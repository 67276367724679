.loader--banner {
    color: #5e5b5b;
    background: linear-gradient(#FCDBF9 40%, #fcdcdb 90%);
}

.header--banner {
    /*background: linear-gradient(#FCDBF9 40%, #fcdcdb 90%);*/
    background: linear-gradient(
        rgba(85, 85, 85, 0.4), 
        rgba(85, 85, 85, 0.4)
      ), url('/public/img/banner-portfolio.jpg')  center center;
    background-size: cover;
    color: white;
}

.header--banner .highlighted-text {
    display: inline-block;
    position: relative;
    opacity: 1;
    z-index: 1;
}

.header--banner .highlighted-text::after {
    display: inline-block;
    content: "";
    width: 100%;
    height: 30px;
    background-color: #ff67f2;
    opacity: 0.8;
    position: absolute;
    bottom: 0; left: 0;
    z-index: -1;
}

.main-nav li.menu-item {
    position: relative;
    z-index: 1;
}

.main-nav li.menu-item::after {
    display: inline-block;
    content: "";
    width: 0;
    height: 10px;
    background-color: #ff67f2;
    opacity: 0.8;
    position: absolute;
    bottom: 0; left: 15px;
    z-index: -1;
    transition: width linear 300ms;
}

.main-nav li.menu-item:hover::after {
    width: calc(100% - 30px);
}

.dark {
    .header--banner {
        background: linear-gradient(
        rgba(10, 25, 47, 0.4), 
        rgba(10, 25, 47, 0.4)
        ), url('/public/img/banner-portfolio.jpg') center center;
        background-size: cover;
    }

    .header--banner .highlighted-text::after {
        background-color: #b768f7;
    }

    .main-nav li.menu-item::after {
        background-color: #b768f7;
    }
    
    
}