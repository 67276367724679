:root {
    --screen-width:     320px;
    --screen-height:    560px;
    --splash-bg-color:  #bd8fb9;
}
  

@function circumference($r){
    $pi: 3.141592653;
    @return 2*$pi*$r;
}

.dark-mode-mobile {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 10;
}
  
 .nav {
    .nav__toggle {
      display:inline-block;
      position:absolute;
      z-index:10;
      padding:0;
      border:0;
      background:transparent;
      outline:0;
      right:15px;
      top:15px;
      cursor:pointer;
      border-radius:50%;
      transition:background-color .15s linear;
    }
    
    .nav__menu {
      display:flex;
      flex-direction:column;
      justify-content:center;
      height:var(--screen-height);
      position:relative;
      z-index:5;
    }
    
    .nav__item {
      opacity:0;
      transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
    }

    .nav__item:nth-child(1){
      transform: translateY(-40px);
    }

    .nav__item:nth-child(2){
      transform: translateY(-80px);
    }

    .nav__item:nth-child(3){
      transform: translateY(-120px);
    }

    .nav__item:nth-child(4){
      transform: translateY(-160px);
    }

    .nav__item:nth-child(5){
      transform: translateY(-200px);
    }
    
    
    .nav__link {
      color:white;
      display:block;
      text-align:center;
      text-transform:uppercase;
      letter-spacing:5px;
      font-size:1.25rem;
      text-decoration:none;
      padding:1rem;
      
      &:hover,
      &:focus {
        outline:0;
        background-color:rgba(0,0,0,0.2);
      }
    }
  }
  
  .menuicon {
    display:block;
    cursor:pointer;
    color: #fff;
    
    transform:rotate(0deg);
    transition: .3s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
    
    .menuicon__bar,
    .menuicon__circle {
      fill:none;
      stroke: currentColor;
      stroke-width:3;
      stroke-linecap:round;
    }
    .menuicon__bar {
      transform: rotate(0deg);
      transform-origin:50% 50%;
      transition: transform .25s ease-in-out;
      color: #fff;
    }
    .menuicon__circle {
      transition: stroke-dashoffset .3s linear .1s;
      stroke-dashoffset:circumference(23);
      stroke-dasharray:circumference(23);
    }
  }

  .splash {
    position:absolute;
    top:40px;
    right:40px;
    width: 1px;
    height: 1px;
    
    &::after {
      content:"";
      display:block;
      position:absolute;
      border-radius:50%;
      background-color:var(--splash-bg-color);
      width:284vmax;
      height:284vmax;
      top:-142vmax;
      left:-142vmax;
      
      transform: scale(0);
      transform-origin:50% 50%;
      transition: transform .5s cubic-bezier(0.755, 0.050, 0.855, 0.060);
      will-change:transform;
      z-index: 5;
    }
  }
  
  .nav:target,
  .nav--open {
    
    > .splash::after {
      transform:scale(1);
    }
    
    .menuicon {
      color:#fff;
      transform:rotate(180deg);
      
      .menuicon__circle {
        stroke-dashoffset:0;
      }
      .menuicon__bar:nth-child(1),
      .menuicon__bar:nth-child(4) {
        opacity:0;
        color:#fff;
      }
      .menuicon__bar:nth-child(2) {
        transform: rotate(45deg);
        color:#fff;
      }
      .menuicon__bar:nth-child(3) {
        transform: rotate(-45deg);
        color:#fff;
      }
    }
    .nav__item {
      opacity:1;
      transform:translateY(0);
    }

    .nav__toggle {    
      &:hover,
      &:focus {
        background-color:rgba(0,0,0,.5);
      }
    }
    
}

.dark {
  .menuicon__bar {
    color: #fff;
  }
}