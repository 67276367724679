@import './scss/animations.scss';
@import './scss/header.scss';
@import './scss/homepage.scss';
@import './scss/page.scss';
@import './scss/mobilenav.scss';

.dark .App {
    background-color: #0a192f;
}

#root {
    position:relative;
    overflow-x:hidden;
    width: 100%;
}